import React, { useState } from "react";
import { navigate } from "gatsby";
import { getUser, setUser } from "../../utils/auth";

const ProfileComponent = (props) =>
{
  const [newEmail, setNewEmail] = useState(getUser().email);
  const [newPassword, setNewPassword] = useState("");
  const [reEnterNewPassword, setReEnterNewPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [isNewEmailValid, setIsNewEmailValid] = useState(true);
  const [isNewPasswordValid, setIsNewPasswordValid] = useState(true);
  const [isReEnterNewPasswordValid, setIsReEnterNewPasswordValid] = useState(true);
  const [isCurrentPasswordValid, setIsCurrentPasswordValid] = useState(true);
  const [verifyWithCurrentPassword, setVerifyWithCurrentPassword] = useState(false);

  const handleSubmit = e =>
  {
    e.preventDefault();

    var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    
    const newEmailValidationState = newEmail !== null && newEmail.length !== 0 && re.test(newEmail);
    const newPasswordValidationState = newPassword !== null && newPassword.length !== 0;
    const reEnterNewPasswordValidationState = reEnterNewPassword === newPassword;

    setIsNewEmailValid(newEmailValidationState);
    setIsNewPasswordValid(newPasswordValidationState);
    setIsReEnterNewPasswordValid(reEnterNewPasswordValidationState);

    if (newEmailValidationState
      && newPasswordValidationState
      && reEnterNewPasswordValidationState)
    {
      if (!verifyWithCurrentPassword)
      {
        setVerifyWithCurrentPassword(true);
      }
      else
      {
        const currentPasswordValidationState = currentPassword !== null && currentPassword.length !== 0;

        setIsCurrentPasswordValid(currentPasswordValidationState);
  
        if (currentPasswordValidationState)
        {
          // Attempt to Update...
          props.updateUser(getUser().email, currentPassword, newEmail, newPassword);
        }
      }
    }
    else
    {
      setVerifyWithCurrentPassword(false);
    }
  };

  let content;

  if (props.updateUserResults.loading)
  {
    content = <div></div>;
  }
  else if (props.updateUserResults.error)
  {
    content = <div>{ props.updateUserResults.error.message }</div>;
  }
  else if (props.updateUserResults.data)
  {
    content = <div></div>;
    setUser(props.updateUserResults.data.updateUser);
    navigate(`/admin`);
  }
  else
  {
    content = (
      <form
        onSubmit = { handleSubmit }
      >
        <div
          className = "mb-6"
        >
          <label
            className="block font-bold mb-2 text-xs uppercase"
            htmlFor="newEmail"
          >
            New Email
          </label>

          <div
            className = "px-3 py-2"
          >
            <input
              id = "newEmail"
              className = "appearance-none block border-gray-400 border px-3 py-2 rounded text-gray-700 w-full"
              value = { newEmail }
              type = "text"
              name = "email"
              onChange =
              {
                event =>
                {
                  setNewEmail(event.target.value);
                }
              }
            />
          </div>

          {
            !isNewEmailValid &&
            <p
              className = "px-3 text-red-500 text-xs italic"
            >
              Please enter a valid email address.
            </p>
          }
        </div>

        <div
          className = "mb-6"
        >
          <label
            className="block font-bold mb-2 text-xs uppercase"
            htmlFor="newPassword"
          >
            New Password
          </label>

          <div
            className = "px-3 py-2"
          >
            <input
              id = "newPassword"
              className="appearance-none block border-gray-400 border px-3 py-2 rounded text-gray-700 w-full"
              type="password"
              name="password"
              onChange =
              {
                event =>
                {
                  setNewPassword(event.target.value);
                }
              }
            />
          </div>
        </div>

        <div
          className = "mb-6"
        >
          <label
            className="block font-bold mb-2 text-xs uppercase"
            htmlFor="reEnterNewPassword"
          >
            Re-Enter New Password
          </label>

          <div
            className = "px-3 py-2"
          >
            <input
              id = "reEnterNewPassword"
              className="appearance-none block border-gray-400 border px-3 py-2 rounded text-gray-700 w-full"
              type="password"
              name="password"
              onChange =
              {
                event =>
                {
                  setReEnterNewPassword(event.target.value);
                }
              }
            />
          </div>

          {
            (
              () =>
              {
                if (!isNewPasswordValid)
                {
                  return(
                    <p
                      className = "px-3 text-red-500 text-xs italic"
                    >
                      Please enter a valid password.
                    </p>
                  );
                }

                if (!isReEnterNewPasswordValid)
                {
                  return(
                    <p
                      className = "px-3 text-red-500 text-xs italic"
                    >
                      Passwords do not match.
                    </p>
                  );
                }

                return;
              }
            )()
          }
        </div>

        {
          verifyWithCurrentPassword &&
            <div
              className = "mb-6"
            >
              <label
                className="block font-bold mb-2 text-xs uppercase"
                htmlFor="verifyWithCurrentPassword"
              >
                Current Password
              </label>

              <div
                className = "px-3 py-2"
              >
                <input
                  id = "currentPassword"
                  className="appearance-none block border-gray-400 border px-3 py-2 rounded text-gray-700 w-full"
                  type="password"
                  name="password"
                  onChange =
                  {
                    event =>
                    {
                      setCurrentPassword(event.target.value);
                    }
                  }
                />
              </div>

              {
                !isCurrentPasswordValid &&
                  <p
                    className = "px-3 text-red-500 text-xs italic"
                  >
                    Please enter your passowrd.
                  </p>
              }
            </div>
        }
  
        <div
          className = "text-center sm:text-left"
        >
          <button
            className = "border-b-4 border-gray-800 hover:border-gray-700 bg-gray-700 hover:bg-gray-600 font-bold px-4 py-2 rounded text-sm text-white"
          >
            { !verifyWithCurrentPassword ? "Update" : "Confirm" }
          </button>
        </div>
      </form>
    );
  }

  return (
    <div>
      { content }
    </div>
  );
};

export default ProfileComponent;