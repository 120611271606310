import React from "react";
import AdminLayout from "../../components/admin/adminLayout";
import ProfileComponent from "../../components/admin/profileComponent";
import Apollo from "../../components/apollo";
import { updateUserMutation } from "../../utils/staticQueryFragments";
import SEO from "../../components/seo";
import WithPrivateRoute from "../../components/admin/withPrivateRoute";

const Profile = (props) =>
{
  return (
    <AdminLayout>
      <SEO
        keywords = { [`LawChest`, `admin`, `update`, `profile`, `details`] }
        title = "Update Profile"
        isAdmin = { true }
      />
      <Apollo
        propsToInsert = {
          (queryResults, { mutation1, mutation1Results }) => (
            {
              updateUser: (currentEmail, currentPassword, newEmail, newPassword) =>
              {
                mutation1(
                  {
                    variables:
                    {
                      currentEmail: currentEmail,
                      currentPassword: currentPassword,
                      newEmail: newEmail,
                      newPassword: newPassword
                    }
                  }
                );
              },
              updateUserResults: mutation1Results
            }
          )
        }
        mutation1 = { updateUserMutation }
      >
        <ProfileComponent />
      </Apollo>
    </AdminLayout>
  );
};

export default WithPrivateRoute(Profile);